import { update, index, destroy, show, viewed, get_cart } from "@/api/order";

const state = {
    orders: [],
    defaultOrder: {
        name: "",
        price: "",
        image: "",
        description: "",
        categories: []
    },
    order: {},
    cart: {},
};

const getters = {
    defaultOrder: state => {
        return state.defaultOrder;
    },
    orders: state => {
        return state.orders;
    },
    order: state => {
        return state.order;
    },
    cart: state => {
        return state.cart;
    }
};

const mutations = {
    setOrder: (state, order) => {
        state.order = order;
    },
    setOrders: (state, orders) => {
        state.orders = orders;
    },
    setCart: (state, cart) => {
        state.cart = cart;
    },
};
const actions = {
    async setOrders({ commit }, query) {
        let { data } = await index(query);
        commit("setOrders", data);
        return data;
    },
    async updateOrder(context, order) {
        await update(order);
    },
    async destroyOrder(context, order) {
        await destroy(order);
    },
    async getOrder({ commit }, order) {
        let { data } = await show(order);
        commit("setOrder", data);
    },
    async getCart({ commit }, order) {
        let { data } = await get_cart(order);
        commit("setCart", data);
    },
    // eslint-disable-next-line no-unused-vars
    async updateViewed({ commit }, payload) {
        await viewed(payload);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
};
