export default {
    'delete': "Verwijderen",
    'cancel': "Annuleren",
    'yes': "Ja",
    'no': "Nee",
    'pdf': "PDF",
    'order': "Order",
    'my-orders': "Mijn bestellingen",
    'print-labels': "druk labels af",
    'delete-labels': "labels verwijderen",
    'mail-labels': "labels mail",
    'customer-name': "Naam klant",
    'details': "Details",
    'customer-email': "E-mail klant",
    'customer-phone': "Telefoon klant",
    'delivery-time': "Levertijd",
    'delivery-date': "Leverdatum",
    'enabled': "ingeschakeld",
    'enter-subject': "onderwerp invoeren",
    'email-subject': "e-mail onderwerp",
    'template-for': "sjabloon voor",
    'preview': "Voorbeeld",
    'template-context-info': 'Als u een vermelde variabelenaam tussen accolades plaatst, wordt de variabele in de sjabloon geïnjecteerd die naar de klant wordt verzonden.',
    'variable': "Variabel",
    'close': "Sluiten",
    'search': "Zoeken",
    'select-shipping': "Kies leveringsdatum en tijd",
    'submit': "Verzenden",
    'shopping-cart': "Winkelmandje",
    'total': "Totaal",
    'submit-order': "Order verzenden",
    'day': "Dag",
    'error-login': "Fout bij inloggen",
    'evening': "Avond",
    'notifications': "Notificaties",
    'no-hours-available': "Geen beschikbare uren, kies een andere dag.",
    'the-date-has-not': "Er is nog geen datum geselecteerd.",
    'order-not-placed': "Order nog niet geplaatst, fout!",
    'order-successfully-created': "Het order is geplaatst",
    'payment-status': "Status betaling",
    'card-holder-name': "Naam kaarthouder",
    'login': "Login",
    'login-with-google': "Login via Google",
    'login-with-github': "Login via Github",
    'dashboard': "Dashboard",
    'profile': "Profiel",
    'logout': "Uitloggen",
    'clear': "Leegmaken",
    'add-to-cart': "Toevoegen",
    'there-are-no-products': "Er zijn geen producten beschikbaar.",
    'return': "Keer terug",
    'update': "Wijzig",
    'create': "Aanmaken",
    'save': "Bewaren",
    'back': "Terug",
    'save-changes': "Bewaar de wijzigingen",
    'reset-password': "Wachtwoord opnieuw instellen",
    'created': "Aangemaakt",
    'in-processing': "In behandeling",
    'completed': "Klaar",
    'last-10-orders': "Laatste 10 orders",
    'number-of-orders': "Aantal orders",
    'payment': "Betaling",
    'my-category': "Mijn categorie",
    'new-item': "Nieuw item",
    'are-you-sure-you-want': "Ben je zeker dit item te verwijderen?",
    'are-you-sure-you-want-items': "Ben je zeker deze items te verwijderen?",
    'ok': "OK",
    'select': "Selecteer",
    'your-plan-is': "Jouw plan is",
    'select-plan': "Selecteer plan",
    'no-payment-method': "Geen betalingsmethode gevonden, voeg er één toe.",
    'ending-in': "Eindigend op",
    'exp': "Exp",
    'remove': "Verwijderen",
    'you-subscribing-to': "Je schrijft je in voor",
    'edit-order': "Order aanpassen",
    'order-id': "Order id",
    'created-order': "Aangemaakt order",
    'add-variation': "Variatie toevoegen",
    'add-variation-item': "Variatie-item toevoegen",
    'delete-variation': "Variatie verwijderen",
    'variation': "variatie",
    'price-change': "Prijs wijziging",
    'variations': "Variaties",
    'variation-name': "Variatie naam",
    'variation-value': "Variatie waarde",
    'image': "Afbeelding",
    'categories': "Categorieën",
    'price': "Prijs",
    'type-here-description': "Typ hier de omschrijving...",
    'description': "Omschrijving",
    'product-name': "Product naam",
    'my-product': "Mijn product",
    'this-is-old-page': "Dit is een oude pagina, gebruik een nieuwe instellingen pagina",
    'fields': "Velden",
    'color-theme': "Kleur thema",
    'code': "Code",
    'copy': "Copy",
    'sign-up': "Aanmelden",
    'home': "Home",
    'password-reset': "Reset paswoord",
    'password': "Paswoord",
    'reset': "Reset",
    'about': "Over",
    'forgot-password': "Paswoord vergeten",
    'privacy-policy': "Privacy policy",
    'faq': "FAQ",
    'streamlined-integration': "Gestroomlijnde integratie",
    'your-hassle-free': "Uw probleemloze oplossing, van installatie tot succes",
    'step-1': "Stap 1",
    'let-our-experts': "Laat onze experts uw belangrijkste behoeften en doelen vaststellen.",
    'step-2': "Stap 2",
    'smooth-onboarding': "Een soepele onboarding en installatie wachten op u.",
    'step-3': "Stap 3",
    'experience-personalized': "Ervaar gepersonaliseerde softwaretuning op basis van uw feedback.",
    'step-4': "Stap 4",
    'dive-into-comprehensive': "Duik in uitgebreide softwaretrainingssessies.",
    'step-5': "Stap 5",
    'congratulations-you-are-all': "Gefeliciteerd, je bent helemaal klaar om te gaan!",
    'get-started-for-free': "Start gratis",
    'optimize-delivery-logistics': "Optimaliseer de leveringslogistiek: plan eenvoudig",
    'streamline-your-delivery': "Stroomlijn uw bezorglogistiek moeiteloos met onze intuïtieve planner. Stel uw beschikbare verzendtijden, werktijden, feestdagen en meer in.",
    'organize-your-logistics': "Organiseer uw logistiek",
    'improve-efficienty': "Efficientie verbeteren",
    'attract-more-customers': "Trek meer klanten aan",
    'online-booking-software': "Online booking software en een all-in platform",
    'besteller-powerful-and-simple': "Besteller - Krachtige en eenvoudige e-commerce oplossing",
    'for-scheduling': "om te plannen",
    'about-us': "Over ons",
    'clients': "Clients",
    'free-trial': "Probeer gratis",
    'blog': "Blog",
    'contacts': "Contacten",
    'contact-us': "Contacteer ons",
    'register': "Registreer",
    'sign-in': "Aanmelden",
    'effortsless-order': "Moeiteloos order- en voorraadbeheer met geïntegreerde leveringsplanning",
    'discover-seamless-control': "Ontdek naadloze controle over uw bedrijfsvoering met onze uitgebreide order- en productbeheersoftware. Stroomlijn moeiteloos de voorraad, volg bestellingen en optimaliseer leveringsschema's, allemaal op één intuïtief platform. Neem de leiding over uw workflow en verhoog de efficiëntie van uw bedrijf.",
    'easy-to-use': "Eenvoudig te gebruiken voorraadbehee",
    'no-need-to-pay': "U hoeft niet te betalen voor meer beheertools",
    'many-useful-actions': "Veel nuttige acties",
    'online-orders-acceptance': "Acceptatie van online bestellingen",
    'get-more-orders-online': "Ontvang meer bestellingen online door onze volledig aanpasbare winkelwidget op uw website te integrere",
    'online-support': "Online hulp",
    'you-are-not-alone': "Je bent niet de enige. Wij staan ​​voor u klaar met onze professionele online service, die uw vragen tijdig zal beantwoorden.",
    'client-database': "Client database",
    'keep-track-of-all-clients': "Keep track of all clients' data like appointment history, no shows and membership status with simple-to-use CRM software.",
    'powerful-management-tools': "Krachtige beheertools",
    'overview-and-manage': "Overzicht en beheer uw bestellingen, producten, leveringen en werkzaamheden met handige geïntegreerde tools",
    'seamless-integration': "Naadloze integratie",
    'its-very-easy': "Het is heel eenvoudig om aan de slag te gaan en ons systeem in uw website te integreren. Je hoeft geen programmeur te zijn.",
    'inventory-and-accounting': "Inventory and accounting",
    'monitor-consumables-and-items': "Monitor consumables and items in stock with inventory tool as part of our ERP system. Get procurement, staff salaries and other expense reports.",
    'revenue-increase': "omzetstijging",
    'more-monthly-orders': "meer maandelijkse bestellingen",
    'easy-to-use-solution': 'eenvoudig te gebruiken oplossing',
    '10-million': "10 million",
    'appointments-booked-per-month': "appointments booked per month",
    'professionals-available': "professionals available for online booking",
    'boost-orders-and-revenue': "Verhoog bestellingen en omzet door onze webshopwidget te integreren",
    'revolutionize-your-sales': "Breng een revolutie teweeg in uw verkoopproces met onze krachtige webshopwidget. Vereenvoudig het bestellen, beheer producten moeiteloos en zie hoe uw omzet stijgt. Neem vandaag nog de controle over uw bedrijfsgroei!",
    'all-in-one': "Alles-in-één widget - winkelwagen, bestellingen, leveringen",
    'easy-integration': "Eenvoudige integratie met uw website",
    'user-friendly-interface': "Gebruiksvriendelijke interface",
    'allergenen': "Allergenen",
    'ingredients': "Ingrediënten",
    'catalog': "Catalogus",
    'name': "Naam",
    'phone': "Telefoonnummer",
    'email': "E-mail adres",
    'log-in': "Inloggen",
    'order-details': "Order details",
    'users': "Gebruiker",
    'schedule': "Planning",
    'price-plans': "Tariefplan",
    'orders': "Bestellingen",
    'widget-settings': "Widget Instellingen",
    'shop-settings': "Winkel Instellingen",
    'products': "Product",
    'are-you-sure': "Weet je het zeker?",
    'title': "Titel",
    'qty': "Qty",
    'monthly-price': "Maandelijkse Prijs",
    'active': "Actief",
    'activated': 'Ja',
    'not_activated': 'Nee',
    'loading-please-wait': "Laden... Geduld",
    'billing': "Billing",
    'billing-checkout': "Billing checkout",
    'choose-working-hours': "Kies werkuren",
    'build-a-widget': "Een widget maken",
    'mon': "Ma",
    'tue': "Di",
    'wed': "Wo",
    'thu': "Do",
    'fri': "Vr",
    'sat': "Za",
    'sun': "Zo",
    'font': "Letter",
    'id': "id",
    'status': "Status",
    'password-confirmation': "Wachtwoord Bevestiging",
    'password-reset-confirmed': "Wachtwoord Reset Bevestigd",
    'select-a-localization': "Selecteer een lokalisatie",
    'mollie-key': "Mollie key",
    'processing-time-in-hours': "Verwerkingstijd in uren",
    'open': "Open",
    'paid': "Betaald",
    'canceled': "Geannuleerd",
    'expired': "Verlopen",
    'failed': "Mislukt",
    'new': "Nieuw",
    'number-of-orders-per-hour': "Aantal bestellingen per uur",
    'time-zone': "Tijdzone",
    'text-color': "Tekstkleur",
    'image-url': "Afbeelding url",
    'type-here-ingredients': "Typ hier de ingrediënten...",
    'type-here-allergenen': "Typ hier informatie over \"allergenen\"...",
    'select-a-shop-mode': "Selecteer een winkelmodus",
    'energize-your-business': "Geef uw bedrijf een boost met een krachtig platform voor winkelbeheer",
    'automate-your-shop': "Automatiseer uw winkel met gebruiksvriendelijke en krachtige tools voor bestellingen en productbeheer, met geïntegreerde bezorgopties, werktijd- en vakantieplanning",
    'time': "Tijd",
    'actions': "Actie",
    'date': "Datum",
    'agreement': "Overeenkomst",
    'pickup-at': "Ophalen bij",
    'amount': "Totaal",
    'we-deliver-orders': "Wij leveren bestellingen",
    'delivery-price': "Levering Prijs",
    'delivery-desc': "Levering beschrijving",
    'zipcode': "Postcode",
    'min-order-cost': "Min. Bestelkosten",
    'cost': "Kosten",
    'deliver-to-other-zipcodes': "Leveren aan andere zipcodes",
    'add-zipcodes': "Zipcodes toevoegen",
    'pick-up': "Halen",
    'delivery': "Levering",
    'address': "Adres",
    'info': "Info",
    'number-of-add-items-per-hour': "Aantal items per uur toevoegen",
    'sorry-we-dont-delivery': "Gelieve ons te contacteren om deze bestelling te kunnen valideren",
    'the-time-of-delivery-depends': "Het uur van levering is afhankelijk van het verkeer en de meest logische route. We houden zo goed als mogelijk rekening met uw gewenste leveruur. Alle lunches worden geleverd tussen 11u en 12u30, de vrijdagmiddaglevering mag u verwachten tussen 15u en 18u.",
    'all': "Alle",
    'extra_processing_time': "Extra processing time",
    'payment_method': "Betaalmethoden",
    'payment_methods': "Betaalmethoden",
    'payment_method_cash': "Contant/Op factuur",
    'payment_method_card': "Online betalen",
    'required-fields': "Verplichte velden",
    'required-end-date': "Vereiste Begindatum en einddatum",
    'required-end-time': "Vereiste begin - en eindtijd",
    'times': "Tijd",
    'dates': "Datum",
    'go-to-checkout': "Naar winkelmand",
    'vat-number': "BTW nummer",
    'today': "Vandaag",
    'yesterday': "Gisteren",
    'weekly': "Wekelijks",
    'templates': "Sjablonen",
    'subject': 'Onderwerp',
    'type': 'Type',
    'body': "kern van de brief",
    'email_subject': "Onderwerp",
    'email_text': "Tekst",
    'excel-export': "Excel exporteren",
    'holiday': "Vakantie",
    'vat-not-found': "Dit BTW-nummer is niet gevonden",
    'notify_about_promotions': "Informeer over promoties",
    'save-an-account': "Een account opslaan",
    'account': "Rekening",
    'only-pickup': "Alleen ophalen",
    'notify_order_created': 'Er is zojuist een nieuwe bestelling aangemaakt',
    'msg-orders-per-hour-is-exceeded': 'Foutmelding bij overschrijding van het aantal bestellingen per uur',
    'add-new': "Nieuw toevoegen",
    'template-select-placeholder': 'Sjabloon voor',
    'send-email': 'E-mail verzenden',
    'ready-to-go': 'Klaar om te gaan?',
    'lets-start': 'Laten we beginnen',
    'dialog-email-send-title': 'E-mailsjablonen',
    'repeat-last-order': 'Kopieer van vorige bestelling',
    'send-message': 'Bericht versturen',
    'message': 'Bericht',
    'unavailable-items-from-last-order': 'Sommige items van jouw vorige bestelling zijn niet beschikbaar',
    'add-item-to-cart': 'Voeg artikel toe aan de winkelwagen',
    'what-types-of-business': 'Welke soorten bedrijven kunnen profiteren van uw diensten?',
    'our-services-are-designed': 'Onze services zijn ontworpen om tegemoet te komen aan een breed scala aan bedrijven, waaronder winkels, dienstverlenende bedrijven, e-commercewebsites en meer. Of u nu een kleine start-up of een gevestigde onderneming bent, onze oplossingen kunnen worden afgestemd op uw specifieke behoeften.',
    'how-do-i-get': 'Hoe ga ik aan de slag met jullie diensten?',
    'getting-started-is-easy': 'Aan de slag gaan is eenvoudig! Meld u eenvoudig aan of klik op de knop \'Gratis aan de slag\', waarna een van onze experts contact met u opneemt om uw vereisten te bespreken en de beste oplossing voor uw bedrijf te bepalen.',
    'what-sets-your-company': 'Wat onderscheidt uw bedrijf van de concurrentie?',
    'we-pride-ourselves': 'We zijn trots op onze persoonlijke aanpak en toewijding aan klanttevredenheid. Ons begeleide installatieproces, voortdurende ondersteuning en toewijding aan voortdurende verbetering zorgen ervoor dat u het meeste uit onze services haalt. Bovendien onderscheidt onze expertise op het gebied van e-commerce ons in het leveren van op maat gemaakte oplossingen voor uw bedrijf.',
    'is-your-software': 'Is uw software compatibel met bestaande systemen of platforms?',
    'yes-our-software': 'Ja, onze software is ontworpen om naadloos te integreren met een verscheidenheid aan bestaande systemen en platforms. Of u nu WordPress, Shopify of andere applicaties van derden gebruikt, we kunnen onze oplossingen aanpassen zodat ze harmonieus samenwerken met uw huidige configuratie.',
    'how-can-i-get': 'Hoe kan ik contact opnemen met uw ondersteuningsteam als ik vragen heb of hulp nodig heb?',
    'were-here-to-help': 'Wij zijn hier om te helpen! U kunt ons supportteam bereiken via de contactpagina of onze sociale mediakanalen. Onze deskundige vertegenwoordigers zijn beschikbaar om u te helpen met eventuele vragen of problemen die u tegenkomt, zodat u verzekerd bent van een soepele ervaring met onze diensten.',
    'seamless-storefront-solution': 'Naadloze Storefront-oplossing: vereenvoudig, pas aan, succes!',
    'your-hassle-free-integrate': 'Uw probleemloze oplossing, van installatie tot succes. Integreer en beheer eenvoudig bestellingen, producten en voorraad om uw kosten en stress te verminderen.',
    'we-re-here-to-help': 'We zijn hier om te helpen!',
    'our-team-will-answer': 'Ons team zal uw bericht binnen 24 uur beantwoorden',
    'nomadic-soft-stands': 'Nomadic Soft is een vooraanstaand softwareontwikkelings- en adviesbureau dat zich inzet voor het leveren van uitstekende resultaten. Onze expertise ligt in het maken van impactvolle websites en mobiele applicaties van het hoogste kaliber.',
    'for-besteller': 'Voor Besteller kwam onze inspiratie voort uit de behoefte van onze klanten aan een naadloze integratie en eenvoudige oplossing voor hun e-commerceplatforms. In tegenstelling tot complexe alternatieven streefden we naar eenvoud.',
    'demo': 'Demo',
    'convert-visits-into-sales': 'Zet bezoeken om in verkopen',
    'implements-the-shopping-cart-widget': 'Door de winkelwagenwidget op uw website te implementeren, kunt u uw omzet verhogen door bezoeken om te zetten in verkopen. Gebruikers kunnen kiezen uit betaalmethoden tot bezorgdatum en -tijd.',
    'machine': 'Afhalen aan automaat',
    'store': 'Afhalen in de winkel',
    'reset-machine': 'Deselecteren automaat',
    'vending': 'Machine',
    'machine-order': 'Machine Order',
    'success-send-to-vending': 'Succes verzonden naar vending',
    'uncorrect-response-vending': 'Onjuist antwoord tijdens verzending naar vending',
    'failed-send-vending': 'Verzenden naar verkoopautomaat is mislukt',
    'required_price_field': 'Prijs vereist',
    'invalid_price_number': 'Prijs moet hoog zijn en dan 0',
    'invalid_code_length': 'Code moet 5 symbolen bevatten',
    'dialog_vending_title': 'Vul uw prijs en code in',
    '*price': '*Prijs',
    'printed': 'Gedrukt',
    'mailed': 'Gemaild',
    'schedule-vending-caption': 'Openingstijden verkoopautomaten',
    'from': 'Van',
    'to': 'Naar',
    'available': 'Beschikbaar',
    'point': 'Punt',
    'range': 'Bereik',
    'mailer-settings': 'Mailer Settings',
    'select-a-show-mode': 'Selecteer een weergavemodus voor producten',
}
