export default {
    'delete': "Delete",
    'cancel': "Cancel",
    'yes': "Yes",
    'no': "No",
    'pdf': "PDF",
    'order': "Order",
    'my-orders': "My Orders",
    'print-labels': "print labels",
    'delete-labels': "delete labels",
    'mail-labels': "mail labels",
    'customer-name': "Customer name",
    'details': "Details",
    'customer-email': "Customer email",
    'customer-phone': "Customer phone",
    'delivery-time': "Delivery time",
    'delivery-date': "Delivery date",
    'close': "Close",
    'search': "Search",
    'select-shipping': "Select shipping date and time",
    'submit': "Submit",
    'shopping-cart': "Shopping Cart",
    'total': "Total",
    'submit-order': "Submit order",
    'day': "Day",
    'evening': "Evening",
    'no-hours-available': "No hours available, please choose another day.",
    'the-date-has-not': "The date has not yet been selected.",
    'order-not-placed': "Order not placed, error!",
    'order-successfully-created': "Order successfully created",
    'payment-status': "Payment status",
    'card-holder-name': "Card Holder Name",
    'login': "Login",
    'login-with-google': "Login With Google",
    'login-with-github': "Login With Github",
    'dashboard': "Dashboard",
    'profile': "Profile",
    'logout': "Logout",
    'clear': "Clear",
    'add-to-cart': "Add to cart",
    'there-are-no-products': "There are no products in the catalog.",
    'return': "Return",
    'update': "Update",
    'create': "Create",
    'save': "Save",
    'back': "Back",
    'save-changes': "Save Changes",
    'reset-password': "Reset password",
    'created': "Created",
    'in-processing': "In processing",
    'completed': "Completed",
    'last-10-orders': "Last 10 orders",
    'number-of-orders': "Number of orders",
    'payment': "Payment",
    'my-category': "My Category",
    'new-item': "New Item",
    'are-you-sure-you-want': "Are you sure you want to delete this item?",
    'are-you-sure-you-want-items': "Are you sure you want to delete this items?",
    'ok': "OK",
    'select': "Select",
    'your-plan-is': "Your Plan is",
    'select-plan': "Select Plan",
    'no-payment-method': "No payment method found, please add a payment method.",
    'ending-in': "Ending In",
    'exp': "Exp",
    'remove': "Remove",
    'you-subscribing-to': "You're subscribing to",
    'edit-order': "Edit order",
    'order-id': "Order id",
    'created-order': "Created order",
    'add-variation': "Add variation",
    'add-variation-item': "Add variation item",
    'delete-variation': "Delete variation",
    'variation': "variation",
    'price-change': "Price change {productName}",
    'variations': "Variations",
    'variation-name': "Variation name",
    'variation-value': "Variation value",
    'image': "Image",
    'categories': "Categories",
    'price': "Price",
    'type-here-description': "Type here description text...",
    'description': "Description",
    'product-name': "Product name",
    'my-product': "My Product",
    'this-is-old-page': "This is Old page, please use other Settings page",
    'fields': "Fields",
    'color-theme': "Color Theme",
    'code': "Code",
    'copy': "Copy",
    'sign-up': "Sign Up",
    'home': "Home",
    'password-reset': "Password Reset",
    'password': "Password",
    'reset': "Reset",
    'about': "About",
    'forgot-password': "Forgot Password",
    'privacy-policy': "Privacy policy",
    'faq': "FAQ",
    'streamlined-integration': "Streamlined Integration",
    'your-hassle-free': "Your Hassle-Free Solution from Setup to Success",
    'enabled': "Enabled",
    'enter-subject': "Enter subject",
    'email-subject': "Email subject",
    'template-for': "Template for",
    'preview': "Preview",
    'template-context-info': 'If you surround any listed variable name with curly braces, the variable will be injected into the template sent to the customer.',
    'variable': "Variable",
    'step-1': "Step 1",
    'let-our-experts': "Let our experts pinpoint your main needs and goals.",
    'step-2': "Step 2",
    'smooth-onboarding': "Smooth onboarding and setup await",
    'step-3': "Step 3",
    'experience-personalized': "Experience personalized software tuning based on your feedback.",
    'step-4': "Step 4",
    'dive-into-comprehensive': "Dive into comprehensive software training sessions.",
    'step-5': "Step 5",
    'congratulations-you-are-all': "Congratulations, you're all set to go!",
    'get-started-for-free': "Get started for free",
    'optimize-delivery-logistics': "Optimize delivery logistics: Schedule with ease",
    'streamline-your-delivery': "Streamline your delivery logistics effortlessly with our intuitive scheduler. Set your available shipping times, worktime, holidays and more.",
    'organize-your-logistics': "Organize your logistics",
    'improve-efficienty': "Improve efficiency",
    'attract-more-customers': "Attract more customers",
    'online-booking-software': "Online booking software and all-in-one automation platform",
    'besteller-powerful-and-simple': "Besteller - Powerful and Simple Ecommerce Solution!",
    'for-scheduling': "for scheduling",
    'about-us': "About us",
    'clients': "Clients",
    'free-trial': "Free trial",
    'blog': "Blog",
    'contacts': "Contacts",
    'contact-us': "Contact us",
    'register': "Register",
    'sign-in': "Sign in",
    'effortsless-order': "Effortless order and inventory management with integrated delivery scheduling",
    'discover-seamless-control': "Discover seamless control over your business operations with our comprehensive order and product management software. Effortlessly streamline inventory, track orders, and optimize delivery schedules—all in one intuitive platform. Take charge of your workflow and elevate your business efficiency.",
    'easy-to-use': "Easy-to-use inventory management",
    'no-need-to-pay': "No need to pay for more management tools",
    'many-useful-actions': "Many useful actions",
    'online-orders-acceptance': "Online Orders Acceptance",
    'get-more-orders-online': "Get more orders online by integrating our fully customizable shop widget on your website",
    'online-support': "Online Support",
    'you-are-not-alone': "You are not alone. We’ve got you covered with our professional online service, who will answer your questions in a timely manner.",
    'client-database': "Client database",
    'keep-track-of-all-clients': "Keep track of all clients' data like appointment history, no shows and membership status with simple-to-use CRM software.",
    'powerful-management-tools': "Powerful Management Tools",
    'overview-and-manage': "Overview and manage your orders, products, deliveries and work with useful integrated tools",
    'seamless-integration': "Seamless Integration",
    'its-very-easy': "It’s very easy to get started and integrate our system into your website. You don’t have to be a programmer",
    'inventory-and-accounting': "Inventory and accounting",
    'monitor-consumables-and-items': "Monitor consumables and items in stock with inventory tool as part of our ERP system. Get procurement, staff salaries and other expense reports.",
    'revenue-increase': "revenue increase",
    'more-monthly-orders': "more monthly orders",
    'easy-to-use-solution': 'easy-to-use solution',
    '10-million': "10 million",
    'appointments-booked-per-month': "appointments booked per month",
    'professionals-available': "professionals available for online booking",
    'boost-orders-and-revenue': "Boost orders and revenue by integrating our webshop widget",
    'revolutionize-your-sales': "Revolutionize your sales process with our powerful webshop widget. Simplify ordering, manage products effortlessly, and watch your revenue soar. Take control of your business growth today!",
    'all-in-one': "All-in-one widget - shopping cart, orders, deliveries",
    'easy-integration': "Easy integration with your website",
    'user-friendly-interface': "User friendly interface",
    'allergenen': "Allergens",
    'ingredients': "Ingredients",
    'catalog': "Catalog",
    'name': "Name",
    'phone': "Phone number",
    'email': "E-mail",
    'log-in': "Log in",
    'order-details': "Order details",
    'users': "Users",
    'schedule': "Schedule",
    'price-plans': "Price Plans",
    'orders': "Orders",
    'widget-settings': "Widget Settings",
    'shop-settings': "Shop Settings",
    'products': "Products",
    'are-you-sure': "Are you sure?",
    'title': "Title",
    'qty': "Qty",
    'monthly-price': "Monthly Price",
    'active': "Active",
    'activated': 'Yes',
    'not_activated': 'No',
    'loading-please-wait': "Loading... Please wait",
    'billing': "Billing",
    'billing-checkout': "Billing checkout",
    'choose-working-hours': "Choose working hours",
    'build-a-widget': "Build a widget",
    'energize-your-business': "Energize your business with a powerful shop management platform",
    'automate-your-shop': "Automate your shop with easy-to-use and powerful tools for orders and products management, with integrated delivery options, work time and holidays scheduling",
    'password-reset-confirmed': "Password Reset Confirmed",
    'password-confirmation': "Password Confirmation",
    'error-login': "Invalid credentials",
    'status': "Status",
    'id': "id",
    'client-id': "Client id",
    'category-name': "Category name",
    'select-a-localization': "Select a localization",
    'notifications': "Notifications",
    'mollie-key': "Mollie key",
    'processing-time-in-hours': "Processing time in hours",
    'number-of-orders-per-hour': "Number of orders per hour",
    'time-zone': "Time zone",
    'text-color': "Text color",
    'image-url': "Image url",
    'type-here-ingredients': "Type here ingredients...",
    'type-here-allergenen': "Type here information about \"allergenen\"...",
    'select-a-shop-mode': "Select a Shop mode",
    'open': "Open",
    'paid': "Paid",
    'canceled': "Canceled",
    'expired': "Expired",
    'failed': "Failed",
    'new': "New",
    'mon': "Mon",
    'tue': "Tue",
    'wed': "Wed",
    'thu': "Thu",
    'fri': "Fri",
    'sat': "Sat",
    'sun': "Sun",
    'font': "Font",
    'time': "Time",
    'actions': "Actions",
    'date': "Date",
    'agreement': "Agreement",
    'pickup-at': "Pickup at",
    'amount': "Amount",
    'we-deliver-orders': "We deliver orders",
    'delivery-price': "Delivery price",
    'delivery-desc': "Delivery description",
    'zipcode': "Zipcode",
    'min-order-cost': "Min order cost",
    'cost': "Cost",
    'deliver-to-other-zipcodes': "Deliver to other zipcodes",
    'add-zipcodes': "Add zipcodes",
    'pick-up': "Pick up",
    'delivery': "Delivery",
    'address': "Address",
    'info': "Info",
    'number-of-add-items-per-hour': "Number of add items per hour",
    'sorry-we-dont-delivery': "Sorry we don't delivery to this address yet",
    'the-time-of-delivery-depends': "The time of delivery depends on the traffic and the most logical route. We take your desired delivery time into account as well as possible. All lunches are delivered between 11h and 12h30, the Friday afternoon delivery you can expect between 15h and 18h.",
    'all': "All",
    'extra_processing_time': "Extra processing time",
    'payment_method': "Payment method",
    'payment_methods': "Payment methods",
    'payment_method_cash': "Cash",
    'payment_method_card': "Credit card",
    'required-fields': "Required fields",
    'required-end-date': "Required start date and end date",
    'required-end-time': "Required start time and end time",
    'times': "Times",
    'dates': "Dates",
    'go-to-checkout': "Go to checkout",
    'vat-number': "VAT number",
    'today': "Today",
    'yesterday': "Yesterday",
    'weekly': "Weekly",
    'templates': "Templates",
    'subject': 'Subject',
    'type': 'Type',
    'body': "Body",
    'email_subject': "Subject",
    'email_text': "Text",
    'excel-export': "Excel export",
    'holiday': "Holiday",
    'vat-not-found': "This VAT number was not found",
    'notify_about_promotions': "Notify about promotions",
    'save-an-account': "Save an account",
    'account': "Account",
    'only-pickup': "Only pickup",
    'notify_order_created': 'A new order has just been created',
    'msg-orders-per-hour-is-exceeded': 'Error message when the number of orders per hour is exceeded',
    'add-new': "Add new",
    'template-select-placeholder': 'Template for',
    'send-email': 'Send email',
    'ready-to-go': 'Ready to go?',
    'lets-start': 'Let\'s start',
    'dialog-email-send-title': 'Email Templates',
    'repeat-last-order': 'Repeat last order',
    'unavailable-items-from-last-order': 'Some items from your previous order are unavailable',
    'add-item-to-cart': 'Add item to the cart',
    'send-message': 'Send message',
    'message': 'Message',
    'what-types-of-business': 'What types of businesses can benefit from your services?',
    'our-services-are-designed': 'Our services are designed to cater to a wide range of businesses, including retail stores, service-based businesses, e-commerce websites, and more. Whether you\'re a small startup or an established enterprise, our solutions can be tailored to meet your specific needs',
    'how-do-i-get': 'How do I get started with your services?',
    'getting-started-is-easy': 'Getting started is easy! Simply sign up or click the “Get started for free” button, and one of our experts will reach out to discuss your requirements and determine the best solution for your business.',
    'what-sets-your-company': 'What sets your company apart from competitors?',
    'we-pride-ourselves': 'We pride ourselves on our personalized approach and dedication to customer satisfaction. Our supervised setup process, ongoing support, and commitment to continuous improvement ensure that you get the most out of our services. Additionally, our expertise in ecommerce sets us apart in delivering tailored solutions for your business.',
    'is-your-software': 'Is your software compatible with existing systems or platforms?',
    'yes-our-software': 'Yes, our software is designed to integrate seamlessly with a variety of existing systems and platforms. Whether you\'re using WordPress, Shopify, or other third-party applications, we can customize our solutions to work harmoniously with your current setup.',
    'how-can-i-get': 'How can I get in touch with your support team if I have questions or need assistance?',
    'were-here-to-help': 'We\'re here to help! You can reach our support team via the contact page or our social media channels. Our knowledgeable representatives are available to assist you with any inquiries or issues you may encounter, ensuring a smooth experience with our services.',
    'seamless-storefront-solution': 'Seamless Storefront Solution: Simplify, Customize, Succeed!',
    'your-hassle-free-integrate': 'Your Hassle-Free Solution from Setup to Success. Easily integrate and manage orders, products and inventory, to reduce your cost and stress.',
    'we-re-here-to-help': 'We’re here to help!',
    'our-team-will-answer': 'Our team will answer your message within 24 hours',
    'nomadic-soft-stands': 'Nomadic Soft stands as a premier software development and consulting firm committed to delivering outstanding outcomes. Our expertise lies in crafting impactful websites and mobile applications of the highest caliber.',
    'for-besteller': 'For Besteller, our inspiration stemmed from our clients\' need for a seamless integration and straightforward solution for their e-commerce platforms. Unlike complex alternatives, we aimed to create simplicity.',
    'demo': 'Demo',
    'convert-visits-into-sales': 'Convert visits into sales',
    'implements-the-shopping-cart-widget': 'Implementing the Shopping Cart Widget onto your website will help you boost your sales by converting visits into sales. Users can choose from payment methods, to delivery date and time.',
    'machine': 'Vending machine',
    'store': 'Pick up',
    'reset-machine': 'Cancel machine',
    'vending': 'Vending',
    'machine-order': 'Machine Order',
    'success-send-to-vending': 'Success send to vending',
    'uncorrect-response-vending': 'Uncorrect response while sending to vending',
    'failed-send-vending': 'Failed to send to vending',
    'required_price_field': 'Price required',
    'invalid_price_number': 'Price must be high than 0',
    'invalid_code_length': 'Code must have exactly 5 symbols',
    'dialog_vending_title': 'Fill your price and code',
    '*price': '*Price',
    'printed': 'Printed',
    'mailed': 'Mailed',
    'schedule-vending-caption': 'Vending working hours',
    'from': 'From',
    'to': 'To',
    'available': 'Available',
    'point': 'Point',
    'range': 'Range',
    'mailer-settings': 'Mailer Settings',
    'transport': 'Transport',
    'host': 'Host',
    'port': 'Port',
    'username': 'username',
    'encryption': 'Encryption',
    'domain': 'Domain',
    'secret': 'Secret',
    'endpoint': 'Endpoint',
    'select-a-show-mode': 'Select a show mode products',
}
